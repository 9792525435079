import React, { useCallback, useEffect, useRef, useState } from 'react';
import type { FC } from 'react';
import styled from 'styled-components';
import Text from './Text';
import ArrowDownIcon from 'assets/icons/arrow-drop-down-down.svg';
import ArrowRightIcon from 'assets/icons/arrow-drop-down-right.svg';
import { mobile, useQuery } from 'styles/breakpoints';
import { Analytics } from 'apis/Analytics';
import Tracking from 'utils/tracking';

interface Item {
  question: string;
  answer: string[];
}

interface PeopleAskUsProps {
  title: string;
  list: Item[];
}

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const QuestionContainer = styled.div<{ expanded: boolean }>`
  overflow: ${({ expanded }) => (expanded ? 'auto' : 'hidden')};
  ::-webkit-scrollbar {
    width: 0;
  }
  transition: max-height 0.6s ease;
  border-radius: 1rem;
  background-color: ${({ theme }) => theme.colors.light0};
  cursor: pointer;
  padding: 1.5rem 1.5rem 0;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Title = styled(Text).attrs({
  color: 'dark100',
})`
  text-align: center;

  @media ${mobile} {
    font-weight: 700;
  }
`;

const Question = styled(Text).attrs({
  type: 'tabActive',
  color: 'dark80',
})`
  margin-left: 1rem;
  transition: color 0.2s ease;
  &:hover {
    color: ${({ theme }) => theme.colors.dark60};
  }
  /* fixes large text on ios issue */
  -webkit-text-size-adjust: none;
`;

const List = styled.div`
  margin-top: 3rem;

  div:nth-child(1n):not(:last-child) {
    margin-bottom: 1.5rem;
  }

  @media ${mobile} {
    margin-top: 2rem;
  }
`;

const AnswerContainer = styled.div<{ expanded: boolean }>`
  max-height: ${({ expanded }) => (expanded ? 700 : 0)}px;
  transition: max-height 0.4s ease;
  margin-top: 1.06rem;
  /* hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none;
  scrollbar-width: none;
  overflow-y: auto;
  /* hide scrollbar for Chrom, Safari */
  ::-webkit-scrollbar {
    width: 0;
    display: none;
  }
`;

const ArrowDown = styled(ArrowDownIcon)`
  max-width: 1.5rem;
  min-width: 1.5rem;
`;

const ArrowRight = styled(ArrowRightIcon)`
  max-width: 1.5rem;
  min-width: 1.5rem;
`;

const Answer = styled(Text)<{ expanded: boolean }>`
  padding-bottom: 1.5rem;
  -webkit-text-size-adjust: 100%;
`;

const PeopleAskUs: FC<PeopleAskUsProps> = ({ title, list }) => {
  const [expandedQuestions, setExpandedQuestions] = useState<number[]>([]);
  const childRef = useRef<HTMLDivElement>(null);
  const { isMobile } = useQuery();
  const quiz = new URLSearchParams(location.search).get('lp') ?? 'main';

  const handleQuestionClick = (index: number, item: Item) => {
    handleClick(index);
    Tracking.logEvent({
      event: 'FAQ-clicks',
      funnel: quiz,
      widget: item.question,
    });
  };

  const handleClick = useCallback(
    (index: number) => {
      if (expandedQuestions.includes(index)) {
        setExpandedQuestions(prevState => [
          ...prevState.filter(item => item !== index),
        ]);
        return;
      }
      setExpandedQuestions(prevState => [...prevState, index]);
    },
    [expandedQuestions],
  );

  useEffect(() => {
    if (!childRef.current) return;
  }, [childRef, expandedQuestions]);

  const renderItem = (item: Item, index: number) => {
    const key = `ask-us-question-${index}`;
    const expanded = expandedQuestions.includes(index);

    return (
      <QuestionContainer
        key={key}
        onClick={() => handleQuestionClick(index, item)}
        expanded={expanded}
      >
        <TitleContainer>
          {expanded ? <ArrowDown /> : <ArrowRight />}
          <Question>{item.question}</Question>
        </TitleContainer>

        <AnswerContainer expanded={expanded}>
          <div ref={childRef}>
            {item.answer.map(answer => (
              <Answer
                type="bodyM"
                color="dark80"
                expanded={expanded}
                key={answer}
                dangerouslySetInnerHTML={{ __html: answer }}
              />
            ))}
          </div>
        </AnswerContainer>
      </QuestionContainer>
    );
  };

  return (
    <Container>
      <Title type={isMobile ? 'h2' : 'count'}>{title}</Title>
      <List>{list.map(renderItem)}</List>
    </Container>
  );
};

export default React.memo(PeopleAskUs);
